import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

export const AboutModal = ({ isOpen, handleClose }: Props) => {
  return (
    <>
      <Dialog
        as="div"
        open={isOpen}
        onClose={handleClose}
        transition
        className="fixed inset-0 flex w-screen items-center justify-center bg-black/30 p-4 transition duration-300 ease-out data-[closed]:opacity-0"
      >
      <div className="flex items-center justify-center min-h-screen py-10 px-4 text-center sm:block sm:p-0">
      {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          
        <DialogPanel className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
					<DialogTitle
          	as="h3"
						className="text-lg leading-6 font-medium text-gray-900"
					>
          About
          </DialogTitle>
          <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      This is an open source clone of the game Wordle -{" "}
                      <a
                        href="https://github.com/hannahcode/wordle"
                        className="underline font-bold"
                      >
                        check out the code here
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://www.powerlanguage.co.uk/wordle/"
                        className="underline font-bold"
                      >
                        play the original here
                      </a>
                    </p>
                  </div>
                  <div className="flex gap-4">
								    <button onClick={handleClose}>Close</button>
								  </div>
        </DialogPanel>
        </div>
      </Dialog>
    </>
  );
};

