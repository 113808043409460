import { Dialog, DialogPanel } from '@headlessui/react'
import { HowTo } from "./HowTo";
import { XMarkIcon } from "@heroicons/react/24/outline";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  nome: string;
};

export const InfoModal = ({ isOpen, handleClose, nome }: Props) => {
  return (
    <>
      <Dialog
        as="div"
        open={isOpen}
        onClose={handleClose}
        transition
        className="fixed inset-0 flex w-screen items-center justify-center bg-black/30 p-4 transition duration-300 ease-out data-[closed]:opacity-0"
      >
      <div className="flex items-center justify-center min-h-screen py-10 px-4 text-center sm:block sm:p-0">
      {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          
        <DialogPanel className="inline-block align-top bg-white dark:bg-gray-900 rounded-lg px-4 pt-4 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-top sm:max-w-sm sm:w-full sm:p-4">
	<div className="flex text-center">
	<div className="flex-none w-6"></div>
	<div className="flex-grow">
	<Dialog.Title
		as="h3"
		className="text-lg leading-6 font-medium text-gray-900 dark:text-white"
	>
	Como Jogar		                 
	</Dialog.Title>
	</div>

	<div className="flex-none w-6">
	<div className="fixed right-4 top-4">
	<XMarkIcon
		className="h-6 w-6 cursor-pointer dark:text-white"
		onClick={() => handleClose()}
	/>
	</div>
	</div>
	</div>
	
	<HowTo />
	<p className="pt-3"><hr /></p>
	
	<Dialog.Title
		as="h3"
		className="text-center text-lg leading-6 font-medium text-gray-900 pt-2 dark:text-white"
	>
		Sobre
	</Dialog.Title>
	
	<div className="mt-2 text-left">
		<p className="text-sm text-gray-500 dark:text-gray-200">
		<b>{nome}</b> é uma versão personalizada por alguém do jogo de adivinhar palavras.{" "}
		</p>

		<p className="text-sm pt-1 text-gray-500 dark:text-gray-200">
		O termo deste desafio, é de inteira responsabilidade do usuário que gerou o desafio personalizado. Não passando por nenhuma aprovação do CHARADA.{" "}
		</p>

		<p className="text-sm pt-1 text-gray-500 dark:text-gray-200">
		Crie o seu desafio personalizado {" "}
		<a
		href="/desafie"
		className="underline font-bold select-none"
		target="_blank"
		>
		clicando aqui.
		</a>{" "}</p>
	</div>
        </DialogPanel>
        </div>
      </Dialog>
    </>
  );
};

