import { Dialog, DialogPanel } from '@headlessui/react'
import { NoSymbolIcon, XMarkIcon, ShareIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import { MiniGrid } from "../mini-grid/MiniGrid";
import { shareStatus } from "../../lib/share";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  guesses: string[];
  solution_w: string;
  handleShare: () => void;
};

export const LoseModal = ({
  isOpen,
  handleClose,
  guesses,
  solution_w,
  handleShare,
}: Props) => {
  return (
  <>
      <Dialog
        as="div"
        open={isOpen}
        onClose={handleClose}
        transition
        className="fixed inset-0 flex w-screen items-center justify-center bg-black/30 p-4 transition duration-300 ease-out data-[closed]:opacity-0 dark:bg-white/30"
      >
      <div className="flex items-center justify-center min-h-screen py-10 px-4 text-center sm:block sm:p-0">
      {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          
        <DialogPanel className="inline-block align-top bg-white dark:bg-gray-900 rounded-lg px-4 pt-4 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-top sm:max-w-sm sm:w-full sm:p-4">
	<div className="flex text-center">
	<div className="flex-none w-6"></div>
	<div className="flex-grow">
	<div className="mx-auto flex items-center justify-center h-14 w-14 rounded-full bg-red-100 dark:bg-red-800">
						            <NoSymbolIcon
						              className="h-10 w-10 text-red-600 dark:text-red-200"
						              aria-hidden="true"
						            />
						          </div>
	</div>

	<div className="flex-none w-6">
	<div className="fixed right-4 top-4">
	<XMarkIcon
		className="h-6 w-6 cursor-pointer dark:text-white"
		onClick={() => handleClose()}
	/>
	</div>
	</div>
	</div>
	
	<Dialog.Title
                    as="h3"
                    className="text-center text-lg leading-6 font-medium text-gray-900 dark:text-white"
                  >
                    Não foi dessa vez!
                  </Dialog.Title>
                  
                  <div className="mt-2">
                    <MiniGrid guesses={guesses} />                   
                    <Dialog.Title
                    as="h3"
                    className="text-lg leading-4 font-medium text-gray-700 dark:text-white"
                  >
                    A palavra era: <b>{solution_w}</b>
                  </Dialog.Title>
                  </div>
	

	              <div className="mt-2">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 dark:bg-indigo-700 dark:hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                  onClick={() => {
                    if(shareStatus(guesses, 0, true)){
                       handleShare();
                    };
                  }}
                >
                  Compartilhar<ShareIcon className="mx-2 h-6 w-6 cursor-pointer" />
                </button>
              </div>
             
              <div className="mt-2">
               <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-emerald-600 text-base font-medium text-white hover:bg-emerald-700 dark:bg-emerald-700 dark:hover:bg-emerald-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:text-sm"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Novo Jogo<ArrowPathIcon className="mx-2 h-6 w-6 cursor-pointer" />
                </button>
                </div>
        
        </DialogPanel>
        </div>
      </Dialog>
    </> 
  );
};
