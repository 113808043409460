import React, { useEffect, useRef, useState } from 'react';

interface AtOptions {
  key: string;
  format: string;
  height: number;
  width: number;
  defer: boolean;
  rel: string;
  container: string;
  params: Record<string, any>;
}

export const BannerLeft: React.FC = () => {
  const banner = useRef<HTMLDivElement>(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [isDelayed, setIsDelayed] = useState(false);
  
  const getRandomDelay = (min: number, max: number) => {
      return Math.floor(Math.random() * (max - min + 1)) + min; // Intervalo entre min e max
    };

  useEffect(() => {
   
    const atOptions: AtOptions = {
      key: 'e403ac3f2b2e09966a789ee71734c23e',
      format: 'iframe',
      height: 600,
      width: 160,
      defer: true,
      rel: 'nofollow',
      container: 'atContainer-e403ac3f2b2e09966a789ee71734c23e',
      params: {},
    };
    
    
    const loadBannerWithDelay = () => {
      // Adicionar um delay antes de renderizar o script
      const randomDelay = getRandomDelay(3000, 7000);
      
      const timeout = setTimeout(() => {

    if (banner.current && !scriptLoaded) {
      const conf = document.createElement('script');
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `//www.highperformanceformat.com/${atOptions.key}/invoke.js`;
      
      // Configuração do script
      conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;
           
      script.onload = () => {
        setScriptLoaded(true); // Quando o script for carregado, atualizar o estado
      };
      
      if (!banner.current.querySelector('#script-id')) {
            conf.id = 'script-id';
            script.id = 'script-id';
            banner.current.appendChild(conf);
            banner.current.appendChild(script);
       }

      setIsDelayed(true);
    }
  }, randomDelay);
  
     // Limpeza do timeout se o componente for desmontado
      return () => clearTimeout(timeout);
    };
    
    loadBannerWithDelay();
        
    }, [scriptLoaded]);
   
  return (
    <div id="atContainer-e403ac3f2b2e09966a789ee71734c23e" ref={banner}>
      {/* Exibe o banner ou uma imagem fallback */}
      {!scriptLoaded && (
        <img width="160" height="600" src="./banner_160_600.png" alt="Banner" />
      )}
    </div>
  );
};


export const BannerRight: React.FC = () => {
  const banner = useRef<HTMLDivElement>(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    const atOptions: AtOptions = {
      key: '13cdfa0a72d2f42850afdb86d4310fbe',
      format: 'iframe',
      height: 600,
      width: 160,
      defer: true,
      rel: 'nofollow',
      container: 'atContainer-13cdfa0a72d2f42850afdb86d4310fbe',
      params: {},
    };

    if (banner.current && !scriptLoaded) {
      const conf = document.createElement('script');
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `//www.highperformanceformat.com/${atOptions.key}/invoke.js`;
      
      // Configuração do script
      conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;
      
      script.onload = () => {
        setScriptLoaded(true); // Quando o script for carregado, atualizar o estado
      };

      // Appendando os elementos
      banner.current.append(conf);
      banner.current.append(script);
    }
  }, [scriptLoaded]);

  return (
    <div id="atContainer-13cdfa0a72d2f42850afdb86d4310fbe" ref={banner}>
      {/* Exibe o banner ou uma imagem fallback */}
      {scriptLoaded ? null : (
        <img width="160" height="600" src="./banner_160_600.png" alt="Banner" />
      )}
    </div>
  );
};


export const BannerMobile: React.FC = () => {
  const banner = useRef<HTMLDivElement>(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    const atOptions: AtOptions = {
      key: 'cba894e2cd498df7e68e0878926556ef',
      format: 'iframe',
      height: 50,
      width: 320,
      defer: true,
      rel: 'nofollow',
      container: 'atContainer-cba894e2cd498df7e68e0878926556ef',
      params: {},
    };

    if (banner.current && !scriptLoaded) {
      const conf = document.createElement('script');
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `//www.highperformanceformat.com/${atOptions.key}/invoke.js`;
      
      // Configuração do script
      conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;
      
      script.onload = () => {
        setScriptLoaded(true); // Quando o script for carregado, atualizar o estado
      };

      // Appendando os elementos
      banner.current.append(conf);
      banner.current.append(script);
    }
  }, [scriptLoaded]);

  return (
    <div id="atContainer-cba894e2cd498df7e68e0878926556ef" ref={banner}>
      {/* Exibe o banner ou uma imagem fallback */}
      {scriptLoaded ? null : (
        <img width="320" height="50" src="./banner_320_50.png" alt="Banner" />
      )}
    </div>
  );
};

