import { useState } from 'react'
import { Dialog, DialogPanel } from '@headlessui/react'
import { CurrentRow } from "../grid/CurrentRow";
import { PuzzlePieceIcon, DocumentDuplicateIcon, ShareIcon } from "@heroicons/react/24/outline";
import { copyLink, shareLink } from "../../lib/share";

export const CreateModal = () => {

const [ currentGuesss, setCurrentGuesss ] = useState("");
const [ currentName, setCurrentName ] = useState("#MinhaCharada");
const [ currentLink, setCurrentLink ] = useState("");
const [ errors, setErrors ] = useState("");


  function handleChangeInput(event: any){
    var value = (event.target.value).substring(0, 5).normalize('NFD').replace(/[^a-záàâãéèêíóôõúçA-ZÁÀÂÃÉÈÊÍÓÔÕÚÇ]/g, '').toUpperCase();
    setCurrentGuesss(`${value}`);
  };
  
  function handleChangeNameInput(event: any){
    var value = (event.target.value).trim().normalize('NFD').replace(/[^a-záàâãéèêíóôõúçA-ZÁÀÂÃÉÈÊÍÓÔÕÚÇ@#_1234567890]/g, '');
    event.target.value = value;
    if (value === ""){
       value = "#MinhaCharada";   
    }else{
       value = "Charada " + value;    
    }
    setCurrentName(`${value}`);
  };
  
  function onSubmitHandler(e: any){
    e.preventDefault();
    var nome = e.target.elements.gamename.value.trim();
    var value2 = (e.target.elements.palavra.value).replace(/[^a-záàâãéèêíóôõúçA-ZZÁÀÂÃÉÈÊÍÓÔÕÚÇ]/g, '').toUpperCase();
    e.target.elements.palavra.value = value2;
    
    //var termo = e.target.elements.palavra.value;
    var termo = value2;
    
    if (termo.length !== 5){
    	setErrors("ERRO: A palavra deve conter 5 letras.");    
    	return
    }else{
    	setErrors('');
    }
    
    var encodedString = btoa(termo + ":" + nome);
    
    setCurrentLink("https://charada.app/d/" + encodedString);
    
    if (nome === ''){
    	nome = "#MinhaCharada";
    }else{
        nome = "Charada " + nome;
    }

  };

  return (
  <>
      <Dialog
        as="div"
        className="fixed inset-0 flex w-screen items-center justify-center bg-black/30 p-4 transition duration-300 ease-out data-[closed]:opacity-0 dark:bg-white/30"
        onClose={() => null}
        open={true}
      >
      <div className="flex items-center justify-center min-h-screen py-10 px-4 text-center sm:block sm:p-0">
      {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          
        <DialogPanel className="inline-block align-top bg-white dark:bg-gray-900 rounded-lg px-4 pt-4 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-top sm:max-w-sm sm:w-full sm:p-4">
	<div className="flex text-center">
	<Dialog.Title
		as="h3"
		className="text-center text-lg leading-6 font-medium text-gray-900 dark:text-white"
	>
	#MinhaCharada
	</Dialog.Title>
	</div>

	<div className="m-2">
                    <p className="text-sm text-justify text-gray-500 dark:text-gray-200">
                      Escolha uma palavra personalizada, e desafie sua galera! Quem será capaz de acertar?
                    </p>
                    
                    
                    </div>
                    <hr />
                    <div className="mt-2">
                    
                    
  <form onSubmit={(e)=>{onSubmitHandler(e)}}>
    <div className="mb-4">
      <label className="block text-gray-700 dark:text-gray-200 text-sm font-bold mb-2" htmlFor="gamename">
        Nome / @ / #
      </label>
      <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-100 dark:bg-black leading-tight focus:outline-none focus:shadow-outline" id="gamename" type="text" placeholder="ex. @PalavraCharada" maxLength={25} onChange={handleChangeNameInput} />
    </div>
    <div className="mb-4">
      <label className="block text-gray-700 dark:text-gray-200 text-sm font-bold mb-2" htmlFor="palavra">
        Palavra (5 letras)
      </label>
      <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-100 dark:bg-black leading-tight focus:outline-none focus:shadow-outline" 
      id="palavra"
      type="text"
      placeholder="ex. Fácil"
      minLength={5}
      maxLength={5}
      onChange={handleChangeInput}
      autoComplete="off"
      required
      />
      <p className="text-gray-600 dark:text-gray-200 text-xs italic">No jogo, a acentuação será considerada automaticamente.</p>
    </div>
    
    <div className="mb-4"><p className="text-red-600 text-xs">{errors}</p></div>
    
    <div className="flex items-center">
      <button className="inline-flex justify-center w-full bg-green-500 hover:bg-green-700 dark:bg-emerald-700 dark:hover:bg-emerald-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
        Gerar Desafio <PuzzlePieceIcon className="mx-1 h-6 w-6 cursor-pointer" />
      </button>
    </div>
  </form>
  <hr className="m-2" />
  
  <Dialog.Title
                    as="h3"
                    className="text-center text-lg leading-6 font-medium text-gray-900 dark:text-white"
                  >
                    { currentName }
                  </Dialog.Title>
  
  

                    <div className="flex justify-center mb-1 mt-1">
                    
                      <CurrentRow guess={ currentGuesss } />
                    </div>


                  </div>
                  <hr className="m-2" />
                  
                  <Dialog.Title
                    as="h3"
                    className="text-center text-lg leading-6 pb-2 font-medium text-gray-900 dark:text-white"
                  >
                    Compartilhe
                  </Dialog.Title>
                  
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-100 dark:bg-black leading-tight focus:outline-none focus:shadow-outline" 
      id="shareink"
      type="text"
      placeholder="https://charada.app/d/"
      value={ currentLink }
      readOnly
      />
                  
                  
                  <div className="grid grid-cols-2 mb-3 pt-2 px-2">
                  
<div className="w-full mx-auto px-1 items-center">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-neutral-600 text-base font-medium text-white hover:bg-neutral-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-neutral-500 sm:text-sm"
                  onClick={() => {
                    copyLink(currentLink);
                  }}
                >
                  Copiar<DocumentDuplicateIcon className="mx-1 h-6 w-6 cursor-pointer" />
                </button>
     </div>
     <div className="w-full mx-auto px-1 items-center">           
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 dark:bg-indigo-700 dark:hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                  onClick={() => {
                  if(currentLink!==""){
                    shareLink(currentName,currentLink)};
                  }}
                >
                  Compartilhar<ShareIcon className="mx-1 h-6 w-6 cursor-pointer" />
                </button>
                      
          </div>            
                  </div>


	

        
        </DialogPanel>
        </div>
      </Dialog>
    </> 
  
  );
};
